.createBlogForm {
  height: 100vh;
}
.ReactQuillInput {
  height: 50vh !important;
  padding-bottom: 10vh !important;
  .ql-container.ql-snow {
    height: 50vh;
    .ql-editor.ql-blank {
      p {
        height: 50vh !important;
      }
    }
  }
}
.ql-editor {
  height: 50vh;
}
