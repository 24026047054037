@import "~antd/dist/reset.css";

@import "./views/Home/Home.less";

@import "./App.less";

@import "./views/ContentLayout/ContentLayout.less";

@import "./views/Blog/Blog.less";

@import "./components/layout/AppLayout.less";

@import "./views/CreateBlog/CreateBlog.less";

@font-face {
  font-family: "Hellix";

  src: url("./font/hellix/Hellix-Regular.ttf");
}

@font-face {
  font-family: "Rifton";

  src: url("./font/rifton/Rifton\ Norm.otf");
}

@font-face {
  font-family: "Hellix-bold";

  src: url("./font/hellix/Hellix-Bold.ttf");
}
