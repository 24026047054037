.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}
.logo {
  height: 32px;
  margin: 16px;
  color:white;
  margin-left: 25px;
  /* background: rgba(255, 255, 255, 0.3); */
}
.ant-layout-sider-children{
  min-height: 100vh;;
}