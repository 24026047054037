.contentLayout_bg {
  background: radial-gradient(#2e1811 20%, #0f0f11 70%);
  /* background-repeat: no-repeat; */
  background-size: 100% 900px;
  min-height: 100vh;
}
.o2x_heading {
  font-family: "Rifton";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  color: white;
  padding-bottom: 50px;
  padding-top: 50px;
}
.login_div_design {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.login_div {
  width: 500px !important;
  height: 500px !important;
  background-color: white;
  border-radius: 10px;
  margin: 0 auto;
  padding: 3%;
}
@media screen and (max-width: 768px) {
  .o2x_heading {
    font-size: 32px;
  }
  .login_div {
    width: 400px !important;
    height: 400px !important;
  }
}
@media screen and (max-width: 576px) {
  .o2x_heading {
    font-size: 28px;
  }
  .login_div {
    width: 320px !important;
    height: 380px !important;
  }
}
.loginText {
  font-family: "Hellix-bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: black;
}
